
.normal {
}

.operation a {
  margin: 0 .5em;
}

.create {
  margin: 1.5em 0;
}
