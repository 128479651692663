:focus,
button:focus,
.btn:focus,
.btn-sm:focus,
.form-input:focus,
.form-textarea:focus,
.form-multiselect:focus,
.form-select:focus,
.form-checkbox:focus,
.form-radio:focus {
    @apply outline-blue;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {

    &:focus {
        box-shadow: none !important;
    }
}
[type='text'], [type='email'], [type='url'], [type='password'], [type='number'], [type='date'], [type='datetime-local'], [type='month'], [type='tel'], [type='time'], [type='week'], [multiple], textarea {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff !important;
    border: 1px solid #d9d9d9 !important;
    border-width: 1px;
    border-radius: 2px !important;
    padding-top: 0.5rem;
    padding-right: 0.75rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    font-size: 1rem;
    line-height: 1.5rem;
}

.ant-checkbox-inner, [type='checkbox'] {
    border: 1px solid #f3eeee62 !important;
    background-color: none !important;
    box-shadow: 0px 2px 15px 5px #b3b0b08c !important;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 3px;
    -webkit-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
}
.ant-form input[type='radio'] {
    border: 1px solid #f3eeee62 !important;
    background-color: none !important;
    box-shadow: 0px 2px 15px 5px #b3b0b08c !important;
    width: 20px !important;
    height: 20px !important;
    text-align: center;
    border-radius: 100% !important;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
}